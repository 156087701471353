// Backgrounds & Text & Links & Borders

//------> Dark Color
$bg-color: var(--bg-color);
$assistant-color: var(--assistant-color);
$body-color: var(--font-color);
$heading-color: var(--heading-color);
$border-color: var(--border-color);


$color-blue:  var(--color-blue);
$color-black: var(--color-black);
$color-white:  var(--color-white);
$shadow-black: var(--shadow-black);

$bg-gray: var(--bg-gray);
$bg-dark-gray: var(--bg-dark-gray);
$color-light-blue: var(--bg-light-blue)
